import React from 'react';
import './about.css';
import Gallery from 'react-photo-gallery';

class About extends React.Component {
  render() {
    return (
      <div onScroll={this.handleScroll} className="summer-page-wrapper">
        <div className="row">
          <div className="col-sm-12"></div>
        </div>
        <div className="container-fluid text-center about-page-header-container mobile-wrapper">
          <div className="row">
            <div className="col-sm-3"></div>
            <div className="col-sm-6">
              <p className="summer-header animated fadeIn delay-1s">Meet TheLabs</p>
              <p className="summer-subtitle animated fadeIn delay-2s">A company dedicated to make your education an experience</p>
            </div>
            <div className="col-sm-3"></div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-sm-2"></div>
            <div className="col-sm-8">
              <p data-aos="fade-right" className="summer-section-heading">
                <span className="highlight-text">Our</span> History{' '}
              </p>
              <p data-aos="fade-right" className="subject-text">
                <span className="highlight-text">TheLabs started off </span> as a college committee in the buzzing student hub of Mumbai in Vile
                Parle. With a focus on mapping the two ends of the industry meet, TheLabs has conducted skill based workshops in and around campuses
                which help students to build the right profile. We began our first student chapter in Narsee Monjee Institute of Management Studies.
                In a span of 6 months, TheLabs was successful in setting up a functional team of 50+ students, dedicated to the various domains which
                help the potential student fit right in the market. TheLabs has grown and come a long way in standing up for students and always
                believing in the importance of skill aggregation for a successful career path. TheLabs has been successfully co-hosting the Summer and
                Winter Schools in Telecom Ecole de Management, France since academic year 2016-17 and has exciting program tie ups coming up all round
                the academic year.{' '}
              </p>
              <br />
            </div>
            <div className="col-sm-2"></div>
          </div>
        </div>
        {/* <div data-aos="fade-up" className="">
          <Gallery photos={ROW1} />
        </div> */}
        <div className="container">
          <div className="row">
            <div className="col-sm-2"></div>
            <div className="col-sm-8">
              <p data-aos="fade-left" className="summer-section-heading">
                <span className="highlight-text">People Behind</span> TheLabs{' '}
              </p>
            </div>
            <div className="col-sm-2"></div>
          </div>
        </div>
        <div className="container">
          <div className="row portrait-container">
            <div data-aos="fade-right" className="col-12 col-lg-3 adwait-portrait flexible-container text-center">
              <p data-aos="fade-right" className="summer-section-heading">
                Adwait Goel
              </p>
              <a href="https://www.linkedin.com/in/adwaitgoel">
                <p data-aos="fade-right" className="portrait-text">
                  {' '}
                  <u>LinkedIn</u>{' '}
                </p>
              </a>
            </div>
            <div data-aos="fade-right" className="col-12 col-lg-3 tanishq-portrait flexible-container text-center">
              <p data-aos="fade-right" className="summer-section-heading">
                Tanishq Sharma
              </p>
              <a href="https://linkedin.com/in/tanishqsh/">
                <p data-aos="fade-right" className="portrait-text">
                  {' '}
                  <u>LinkedIn</u>{' '}
                </p>
              </a>
            </div>
            <div data-aos="fade-right" className="col-12 col-lg-3 divyang-portrait flexible-container text-center">
              <p data-aos="fade-right" className="summer-section-heading">
                Divyang Kaushik
              </p>
              <a href="https://in.linkedin.com/in/divyang-kaushik-672b37142?trk=pub-pbmap">
                <p data-aos="fade-right" className="portrait-text">
                  <u>LinkedIn</u>{' '}
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ROW1 = [
  { src: 'https://res.cloudinary.com/labs-international/image/upload/v1573935183/Photo_from_Divyang_kbsti1_qs19mj.jpg', width: 4, height: 3 },
  {
    src: 'https://res.cloudinary.com/labs-international/image/upload/v1573913979/Summer%202017/20049248_1798503160176144_1312802610_o_subkno.jpg',
    width: 4,
    height: 3,
  },
  {
    src: 'https://res.cloudinary.com/labs-international/image/upload/v1573913793/Summer%202017/IMG-20170625-WA0002-01_r6clzh.jpg',
    width: 4,
    height: 3,
  },
  {
    src: 'https://res.cloudinary.com/labs-international/image/upload/v1573913791/Summer%202017/20170629113030_IMG_1089-01_i4fvty.jpg',
    width: 4,
    height: 3,
  },
  {
    src: 'https://res.cloudinary.com/labs-international/image/upload/v1573913980/Summer%202017/20049317_1798504743509319_1788785417_o_i3b0mp.jpg',
    width: 4,
    height: 3,
  },
];

export default About;
