import React from 'react';
import './winter-19-subjects.css';

class Winter19Subjects extends React.Component {
    render() {
        return (
            <div className='element-wrapper'>
                <div className='subject-wrapper container'>
                    <div className='row'>
                        <div className='col-sm-12 subject-section-header text-center'>
                            <p> Subjects included in the course </p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-4 text-left subjectbox'>
                            <p className='subject-title'> big data management </p>
                            <p className='subject-description'>
                                Get your hands dirty over the market disruptor in the form of Big Data Management. The goal of big data management is to ensure a high level of data quality and accessibility for business intelligence and big data analytics applications. Acquire this skill set to stay abreast in the market and what better place than the heart of the European Union.
                            </p>
                        </div>
                        <div className='col-sm-4 text-left subjectbox'>
                            <p className='subject-title'> internet of things </p>
                            <p className='subject-description'>
                                Technology is inescapable. It pervades every facet of our life. From how we work, play and live our lives, technology has created a revolution that will grow for as long as humans continue to advance in their capabilities. A lot of what we do is going to be controlled using artificial intelligence and the internet of things; whether you are a science major or a management student, taking these courses will help you expand your domain and increase your skill set.                            </p>
                        </div>
                        <div className='col-sm-4 text-left subjectbox'>
                            <p className='subject-title'> european economy </p>
                            <p className='subject-description'>
                            The European Union is a unified trade and monetary body of 28 member countries. Several of the world's largest economies are in Europe, and the continent's shared economic zone and currency give it an outsized impact on the global economy. The European Union not only affects your life, but may also transform it. By learning how Europe works, a new world will open up to you and discover new jobs you never heard of.                            </p>
                        </div>
                    </div>
                    <div className='row'> 
                        <div className='col-sm-4 text-left subjectbox'>
                            <p className='subject-title'> france culture and history </p>
                            <p className='subject-description'>
                            ‘When in France, do as the French do’. France doesn't just have culture; the word "culture" actually comes from France. Get to know your home for winters. Dwell into the profound history of France and the culture at the fashion capital of the world.
                                 </p>
                        </div>
                        <div className='col-sm-4 text-left subjectbox'>
                            <p className='subject-title'> data privacy </p>
                            <p className='subject-description'>
                            Data privacy has always been important. It’s why people put locks on filing cabinets and rent safety deposit boxes at their banks. But as more of our data becomes digitised, and we share more information online, data privacy is taking on greater importance. Understand what is the scope of Data Privacy and why it has become an essential field of study.                            </p>
                        </div>
                        <div className='col-sm-4 text-left subjectbox'>
                            <p className='subject-title'> digital social innovation </p>
                            <p className='subject-description'>
                            Wouldn’t it be wonderful if someone taught you how to approach any problem and reach a methodological solution to it. That is what you shall learn in design thinking. Learn this entirely project based, interactive and essential management skill from the industry experts, coming from giant corporates like Microsoft. </p>                        </div>
                        <div className='col-sm-4'>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Winter19Subjects;