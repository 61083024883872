import React from 'react';
import './header.css';
import 'hover.css';
import TextLoop from "react-text-loop";
import Banner from './banner/banner';


const Winter19Image = "https://res.cloudinary.com/labs-international/image/upload/v1572723058/Website%20Assets/back1-min_n5r3qu.jpg";
const Summer19Image = "https://res.cloudinary.com/labs-international/image/upload/v1572723057/Website%20Assets/summer-19-2-min_jjojs7.jpg";
const TestimonialImage = "https://res.cloudinary.com/labs-international/image/upload/v1572723057/Website%20Assets/testimonial-min_womvqp.jpg";


const WHATSAPP_API = "https://api.whatsapp.com/send?phone=919920787468&text=Hi,%20I%20have%20some%20doubts%20about%20the%20program.";



class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            slideImage: Winter19Image,
            headingText: "WINTER 2019",
            subtitleText: "Study and explore Paris & Brussels for Two weeks!",
            buttonText: 'Learn More',
            buttonURL: '#learn-more',
            WPNAV: 'navpills-active',
            PPNAV: 'navpills',
            TENAV: 'navpills'
        };
    }

    onMouseEnterHandlerWP = () => {
        this.setState({
            slideImage: Winter19Image,
            headingText: "WINTER 2019",
            subtitleText: "Study and explore Paris & Brussels for Two weeks!",
            buttonText: 'Learn More',
            buttonURL: '#learn-more',
            WPNAV: 'navpills-active',
            PPNAV: 'navpills',
            TENAV: 'navpills'
        });
    }

    onMouseEnterHandlerPP = () => {
        this.setState({
            slideImage: Summer19Image,
            headingText: "LIMITED SEATS",
            subtitleText: "Register yourself before the seats are over.",
            buttonText: 'Learn More',
            buttonURL: '#learn-more',
            WPNAV: 'navpills',
            PPNAV: 'navpills-active',
            TENAV: 'navpills'

        });
    }

    onMouseEnterHandlerTE = () => {
        this.setState({
            slideImage: TestimonialImage,
            headingText: "Gallery",
            subtitleText: "Cherish The Memories And Prepare For Yours!",
            buttonText: 'Watch',
            buttonURL: '/gallery',
            WPNAV: 'navpills',
            PPNAV: 'navpills',
            TENAV: 'navpills-active'
        });
    }



    render() {
        return (
            <div className='element-wrapper'>
                <div className='header container-fluid'>
                    <div className='row topbar list-inline'>
                        <div className='col-sm-8 text-left'>
                            <TextLoop>
                                <li> Summer 2020 Early Bird Registrations Live Now!  </li>
                                <li> Winter 2019 Registrations Are Now Completed!  </li>
                                <li> Summer 2019 Marksheets Collection Date: 18th September 2019 </li>
                                <li> Winter 2019 Applications for France and &amp; Belgium Open Now </li>
                                <li> NEW! Group Registration Introduction in Winter 2019  </li>
                            </TextLoop>

                        </div>
                        <div className='col-sm-2 text-right'>
                            <li> <a href='mailto:info@thelabs.in'> INFO@THELABS.IN </a> </li>
                        </div>
                        <div className='col-sm-2 text-left'>
                            <li> <a href={WHATSAPP_API}> WHATSAPP: +91 99207 87468</a> </li>
                        </div>
                    </div>
                    <div className='row mainarea'>
                        <div className='col-sm-4 text-center'>
                            <a href='/'>
                                <p className='logo'> THE<b>LABS</b> </p>
                            </a>
                        </div>
                        <div className='col-sm-1'>
                        </div>
                        <div className='col-sm-6 navarea list-inline'>
                            <div className='container'>
                                <div className='row'>
                                    <div
                                        onMouseEnter={this.onMouseEnterHandlerWP}
                                        className={'col-4 ' + this.state.WPNAV}>
                                        <li> <a href='#learn-more'> Winter 2019 </a> </li>
                                        <p className='navpills-subtext'> Registrations Closed </p>
                                    </div>
                                    <div
                                        onMouseEnter={this.onMouseEnterHandlerPP}
                                        className={'col-4 ' + this.state.PPNAV}>

                                        <li> <a href='#apply'> APPLY NOW </a> </li>
                                        <p className='navpills-subtext'> Limited Seats </p>
                                    </div>
                                    <div
                                        onMouseEnter={this.onMouseEnterHandlerTE}
                                        className={'col-4 ' + this.state.TENAV}>
                                        <li> <a href='/gallery'> Gallery </a>  </li>
                                        <p className='navpills-subtext'> Hall Of Fame </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-1'>
                        </div>
                    </div>
                </div>
                
                 <Banner
                    slideImage={this.state.slideImage}
                    headingText={this.state.headingText}
                    subtitleText={this.state.subtitleText}
                    buttonText={this.state.buttonText}
                    buttonURL={this.state.buttonURL}
                />
                    
                
            </div>
        );
    }
}

export default Header;