import React from 'react';
import './gallery.css';
import Gallery from "react-photo-gallery";

class GalleryPage extends React.Component {
    render(){
        return(
            <div className='container-fluid gallery-container'>
            <div className='row'>
                <div className='col-sm-12 text-center'>
                    <p className='gallery-header animated fadeIn delay-1s'> Come, rediscover </p>
                </div>
            </div>
                <div className='container animated fadeInUp delay-2s'>
                  <div className='row'>
                      <div className='col-lg-1 timeline'>
                      </div>
                      <div className='col-lg-11'>
                      <p className='timeline-header'> Summer 2019 </p>
                        <Gallery photos={SUMMER19} />
                        <hr/>
                        <p className='timeline-header'> Winter 2018 </p>
                        <Gallery photos={WINTER18} />
                        <hr/>
                        <p className='timeline-header'> Summer 2018 </p>
                        <Gallery photos={SUMMER18} />
                        <hr/>
                        <p className='timeline-header'> Summer 2017 </p>
                        <Gallery photos={SUMMER17} />
                      </div>
                  </div>
                </div>
            </div>
            
        );
    }
}

const SUMMER17 = [{ 
  src: 'https://res.cloudinary.com/labs-international/image/upload/v1574151015/Gallery/Summer%202017/IMG_20170713_151557_ozfb5h.jpg',
      width:4, height: 3 },
      { src: 'https://res.cloudinary.com/labs-international/image/upload/v1574151015/Gallery/Summer%202017/IMG-20170625-WA0002-01_opsdbr.jpg',
      width: 4, height: 3 },
      { src: 'https://res.cloudinary.com/labs-international/image/upload/v1574151015/Gallery/Summer%202017/IMG_0866-01_flpeoo.jpg',
      width: 3, height: 4 },
      { src: 'https://res.cloudinary.com/labs-international/image/upload/v1574151014/Gallery/Summer%202017/IMG_20170711_120416_afpp0c.jpg',
        width: 3, height: 4 },
      { src: 'https://res.cloudinary.com/labs-international/image/upload/v1574151014/Gallery/Summer%202017/IMG_20170711_120357_jhioib.jpg',
        width: 3, height: 4 },
        {src: 'https://res.cloudinary.com/labs-international/image/upload/v1574151014/Gallery/Summer%202017/IMG_20170711_102400_rs7nl5.jpg',
        width:3, height: 4 },
        { src: 'https://res.cloudinary.com/labs-international/image/upload/v1574151012/Gallery/Summer%202017/IMG_0837-01_czbzmp.jpg',
        width: 3, height: 4 },
        { src: 'https://res.cloudinary.com/labs-international/image/upload/v1574151012/Gallery/Summer%202017/20170711002757_IMG_2325-01_v5b4ni.jpg',
        width: 4, height: 3 },
        { src: 'https://res.cloudinary.com/labs-international/image/upload/v1574151012/Gallery/Summer%202017/20170711002519_IMG_2314-01_pdnspr.jpg',
         width: 4, height: 3 },
         { src: 'https://res.cloudinary.com/labs-international/image/upload/v1574151011/Gallery/Summer%202017/20170703111841_IMG_1515-01_brr1np.jpg',
          width: 4, height: 3 },
         { src: '  https://res.cloudinary.com/labs-international/image/upload/v1574151011/Gallery/Summer%202017/20170629093512_IMG_1045-01_dcgrwc.jpg',
         width: 4, height: 3 },
         { src: '  https://res.cloudinary.com/labs-international/image/upload/v1574151011/Gallery/Summer%202017/20170629093420_IMG_1043-01_biosj2.jpg',
         width: 4, height: 3 }, 
         { src: '  https://res.cloudinary.com/labs-international/image/upload/v1574151011/Gallery/Summer%202017/20170629093333_IMG_1037-01_xnbugq.jpg',
         width: 4, height: 3 }, 
         { src: '  https://res.cloudinary.com/labs-international/image/upload/v1574151012/Gallery/Summer%202017/20170711003023_IMG_2332-01_iw2w0c.jpg',
         width: 4, height: 3 },      
  ];


  const SUMMER18 = [{ 
    src: 'https://res.cloudinary.com/labs-international/image/upload/v1574152632/Gallery/Summer%202018/WhatsApp_Image_2018-07-11_at_8.22.13_PM_mfyagi.jpg',
        width:4, height: 3 },
        { src: 'https://res.cloudinary.com/labs-international/image/upload/v1574152630/Gallery/Summer%202018/IMG_20180713_155546_wwme62.jpg',
        width: 3, height: 4 },
          {src: 'https://res.cloudinary.com/labs-international/image/upload/v1574152612/Gallery/Summer%202018/IMG_20180626_210452_jqvkgi.jpg',
          width:4, height: 3 },
          { src: 'https://res.cloudinary.com/labs-international/image/upload/v1574152612/Gallery/Summer%202018/IMG_20180626_210535_kdltxw.jpg',
          width: 3, height: 4 },
          { src: 'https://res.cloudinary.com/labs-international/image/upload/v1574152614/Gallery/Summer%202018/IMG_20180625_212620_jdjctc.jpg',
          width: 4, height: 3 },     
          { src: 'https://res.cloudinary.com/labs-international/image/upload/v1574152617/Gallery/Summer%202018/IMG_20180706_153125_jp9wsg.jpg',
          width: 4, height: 3 },      
          { src: 'https://res.cloudinary.com/labs-international/image/upload/v1574152619/Gallery/Summer%202018/IMG_20180709_191522_yzh4dh.jpg',
          width: 4, height: 3 },  
          { src: 'https://res.cloudinary.com/labs-international/image/upload/v1574152612/Gallery/Summer%202018/IMG_20180626_210604_vqhj9e.jpg',
          width: 4, height: 3 },
          { src: 'https://res.cloudinary.com/labs-international/image/upload/v1574152619/Gallery/Summer%202018/IMG_20180709_161102_quyjnr.jpg',
          width: 4, height: 3 },      
    ];

    const WINTER18 = [{ 
      src: 'https://res.cloudinary.com/labs-international/image/upload/v1574153454/Gallery/Winter%202018/WhatsApp_Image_2019-01-13_at_10.52.36_PM_3_jfpkqe.jpg',
          width:4, height: 3 },
          { src: 'https://res.cloudinary.com/labs-international/image/upload/v1574153453/Gallery/Winter%202018/WhatsApp_Image_2019-01-13_at_9.18.09_PM_mq1upb.jpg',
          width: 3, height: 4 },
          { src: 'https://res.cloudinary.com/labs-international/image/upload/v1574153453/Gallery/Winter%202018/WhatsApp_Image_2019-01-13_at_9.17.57_PM_3_ved6tn.jpg',
          width: 4, height: 3 },
          { src: 'https://res.cloudinary.com/labs-international/image/upload/v1574153453/Gallery/Winter%202018/WhatsApp_Image_2019-01-13_at_10.52.36_PM_1_otlrql.jpg',
            width: 3, height: 4 },
            {src: 'https://res.cloudinary.com/labs-international/image/upload/v1574153453/Gallery/Winter%202018/WhatsApp_Image_2019-01-13_at_9.17.57_PM_otkb6s.jpg',
            width:4, height: 3 },
            { src: 'https://res.cloudinary.com/labs-international/image/upload/v1574153455/Gallery/Winter%202018/WhatsApp_Image_2019-01-13_at_10.52.36_PM_p9fznh.jpg',
            width: 4, height: 3 },     
      ];


      const SUMMER19 = [{ 
        src: 'https://res.cloudinary.com/labs-international/image/upload/v1574154182/Gallery/Summer%202019/WhatsApp_Image_2019-07-05_at_7.38.06_PM_dj2n8y.jpg',
            width:4, height: 3 },
            { src: 'https://res.cloudinary.com/labs-international/image/upload/v1574154181/Gallery/Summer%202019/WhatsApp_Image_2019-07-05_at_7.46.18_PM_2_w6l1gu.jpg',
            width: 4, height: 3 },
            { src: 'https://res.cloudinary.com/labs-international/image/upload/v1574154179/Gallery/Summer%202019/WhatsApp_Image_2019-07-04_at_2.47.22_PM_gqjr98.jpg',
            width: 4, height: 3 },
            { src: 'https://res.cloudinary.com/labs-international/image/upload/v1574154180/Gallery/Summer%202019/WhatsApp_Image_2019-07-05_at_7.38.05_PM_1_smzieb.jpg',
              width: 4, height: 3 },
              {src: 'https://res.cloudinary.com/labs-international/image/upload/v1574154178/Gallery/Summer%202019/WhatsApp_Image_2019-07-04_at_2.47.21_PM_vyoh30.jpg',
              width:4, height: 3 },
              { src: 'https://res.cloudinary.com/labs-international/image/upload/v1574154175/Gallery/Summer%202019/WhatsApp_Image_2019-06-24_at_10.36.28_PM_m5w2mr.jpg',
              width: 4, height: 3 },
              { src: 'https://res.cloudinary.com/labs-international/image/upload/v1574154172/Gallery/Summer%202019/WhatsApp_Image_2019-07-05_at_7.46.19_PM_1_ow67nr.jpg',
              width: 4, height: 3 },
              { src: 'https://res.cloudinary.com/labs-international/image/upload/v1574154173/Gallery/Summer%202019/WhatsApp_Image_2019-07-08_at_8.46.13_PM_rvhsrl.jpg',
              width: 4, height: 3 },        
        ];
  
export default GalleryPage;