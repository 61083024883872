import React from 'react';
import './winter-when.css';
import Countdown from 'react-countdown-now';

const ProgramDate = new Date('December 09, 2019');

class WinterWhen extends React.Component {
    render() {
        return (
            <div className='element-wrapper'>
                <div className='container-fluid'>
                    <div className='row boarding-pass-container animated pulse'>
                        <div className='col-sm-1'>
                        </div>
                        <div className='col-sm-10 boarding-pass-inner'>
                            <div className='row boarding-top-row'>
                                <div className='col-sm-9'>
                                    <p> THE<b>LABS</b> (IND to FRA) for 2 weeks.</p>
                                </div>
                                <div className='col-sm-3 hide-in-mobile'>
                                    <p> THE<b>LABS</b></p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-3'>
                                    <p className='subtitle'> Program Name </p>
                                    <p className='heading'> Innovation &amp; Digital Inclusion </p>
                                    <p className='subtitle'> Credits Earned </p>
                                    <p className='big-heading'> 4 ECTS </p>
                                    <a rel='noopener noreferrer' target='_blank' href='https://www.mastersportal.com/articles/388/all-you-need-to-know-about-the-european-credit-system-ects.html'> <p className='learn-more'>Learn More</p></a> 
                                </div>
                                <div className='col-sm-3'>
                                    <p className='subtitle'> Date Of Departure: Batch 1 </p>
                                    <p className='heading'> 9<sup>th</sup> December 2019 (Batch 1) </p> 
                                    <p className='subtitle'> Date Of Departure: Batch 2 </p>
                                    <p className='heading'> 29<sup>th</sup> December 2019 (Batch 2) </p> 
                                    <p className='subtitle'> Seats Left </p>
                                    <p className='big-heading'> 5 </p>
                                </div>
                                <div className='col-sm-3 last-section'>
                                    <p className='subtitle'> Days until departure </p>
                                    <p className='big-heading'>  <Countdown date={ProgramDate} /> </p>

                                    <p className='subtitle'> Countries Included </p>
                                    <p className='big-heading'> 2 </p>

                                </div>
                                <div className='col-sm-3'>
                                    <p className='subtitle'> Study Tours </p>
                                    <p className='heading'> Paris, Belgium </p>
                                    <p className='subtitle'> Company Visits </p>
                                    <p className='heading'> Station F, Orange, BNP Paribas* </p>
                                    <a rel='noopener noreferrer' target='_blank' href='https://drive.google.com/open?id=13ze1hpLLUpljyzPHy_zxpTv-SYCx2nJm' className='apply-button hvr-grow'> Download Brochure </a>
                                </div>
                            </div>
                            <div className='row boarding-bottom-row'>
                                    <div className='col-sm-9'>
                                    <p className='subtitle bottom-row-title'> Frequent Contact Number </p>
                                    <p className='subtitle bottom-row-subtitle'> +91 99207 87468 </p>
                                    </div>
                                    <div className='col-sm-3'>
                                    <p className='subtitle bottom-row-title'> Limited Seats only </p>
                                    </div>
                                </div>
                        </div>
                        <div className='col-sm-1'>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WinterWhen;