import React from 'react';
import ReactPixel from '../pixelConfig';
import "./summer20.css";
import Gallery from "react-photo-gallery";
import WhatsAppIcon from '../images/whatsapp.png';

const ParisImage = "https://res.cloudinary.com/labs-international/image/upload/v1573933976/Summer%202017/Summer%2020%20Page/Explore_tuiolf.png";
const AbhinayaImage = "https://res.cloudinary.com/labs-international/image/upload/v1574233866/Summer%202017/Summer%2020%20Page/AbinayaTestimonial_xtoi4s.jpg";

class Summer20 extends React.Component{

    constructor(props){
        super(props);
        this.state = {
        }

        this.handleScroll = this.handleScroll.bind(this);
        this.generateWHATSAPP_API = this.generateWHATSAPP_API.bind(this);
    }

    generateWHATSAPP_API = () => {
        const num = Math.floor(Math.random() * (10 - 1 + 1) + 1);
        console.log(num);
        if(num%2===0){
            window.location.href = "https://api.whatsapp.com/send?phone=919136019289&text=Hi, I have some doubts about the program.";
        } else{
            window.location.href = "https://api.whatsapp.com/send?phone=919920787468&text=Hi, I have some doubts about the program.";
        }
    }

    handleScroll=() => {
        console.log("Scrollinnngggg");
    }

    componentDidMount(){
        ReactPixel.pageView();
    }

    render(){
        return(
            <div onScroll={this.handleScroll} className='summer-page-wrapper'>
                        <div className='row'>
                                <div className='col-sm-12'>
                                <a target='_blank' without rel="noopener noreferrer" onClick={this.generateWHATSAPP_API}>
                                    <button className='whatsapp-button-docked'> <img src={WhatsAppIcon} width='15px' alt='WhatsApp Icon'/>&nbsp; Immediate Assistance </button>
                                </a>
                                </div>
                           </div>
                 <div className='container-fluid summer-bar'>
                            <div className='row'>
                                <div className='col-sm-12 summer-bar-inner text-center'>
                                    <li className='hide-on-mobile' id='program-title'> Summer Program 2020 </li> 
                                    <li> Overview </li>
                                    <li> Program Details </li> 
                                    <li> Apply Now </li> 
                                    <a target='_blank' without rel="noopener noreferrer" onClick={this.generateWHATSAPP_API}>
                                        <li className='hide-on-mobile'> Immediate Contact </li> 
                                    </a>
                                </div>
                           </div>
                  </div>
                <div className='container-fluid text-center summer-page-header-container mobile-wrapper'>
                    <div className='row'>
                        <div className='col-sm-3'>
                        </div>
                        <div className='col-sm-6'>
                            <p className='summer-header animated fadeIn delay-1s'>France, Belgium & Switzerland</p>
                            <p className='summer-subtitle animated fadeIn delay-2s'>2020 - European Summer School</p>  
                        </div>
                        <div className='col-sm-3'>
                        </div>
                    </div>
                </div>
                <div className='container-fluid'>
                    <div className='row p-100'>
                        <div className='col-sm-1'>
                        </div>
                        <div className='col-sm-5 text-right'>
                            <img data-aos="fade-right" className='img-card-container' height="500px" src={ParisImage} alt="Paris"/>
                        </div>
                        <div className='col-sm-5 flexible-container text-left'>
                            <p data-aos="fade-left" className='summer-mobile-header'><span className="highlight-text">3 Weeks </span> Of European Learning & Exploration. </p>
                        </div>
                        <div className='col-sm-1'>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='row portrait-container'>
                        <div  data-aos="fade-right" className='col-12 col-lg-4 paris-portrait flexible-container text-center'>
                            <p data-aos="fade-right" className='summer-section-heading'>Stay In Paris</p>
                            <p data-aos="fade-right" className='portrait-text'>
                            Paris, France's capital, is a major European city and a global center for art, fashion, gastronomy and culture.
                            </p>
                        </div>
                        <div data-aos="fade-right" className='col-12 col-lg-4 brussels-portrait flexible-container text-center'>
                            <p data-aos="fade-right" className='summer-section-heading'>Visit Brussels</p>
                            <p data-aos="fade-right" className='portrait-text'>
                            Brussels is Belgium’s capital and home to the European Union headquarters. 
                            </p>
                        </div>
                        <div data-aos="fade-right" className='col-12 col-lg-4 geneva-portrait flexible-container text-center'>
                            <p data-aos="fade-right" className='summer-section-heading'>Visit Geneva</p>
                            <p data-aos="fade-right" className='portrait-text'>
                            Headquarters of Europe’s United Nations and the Red Cross, it’s a global hub for diplomacy and banking.  </p>
                        </div>
                    </div>
                </div>

                <div className='container-fluid'>
                    <div className='row p-100'>
                            <div className='col-sm-2'>
                            </div>
                            <div className='col-sm-8'>
                                <p data-aos="fade-up" className='highlight-paragraph'> <span className="highlight-text"> More than a hundred students have experienced the Summer School at IMT over the past 5 years. </span> Come and witness the remarkable journey as you prepare for the professional world. <span className="highlight-text">Be a part of the Summer School'20 </span>spread over 3 weeks, across 3 countries and earn ECTS credits for skills in-demand globally. </p> 
                            </div>
                            <div className='col-sm-2'>
                            </div>
                    </div>
                    <br/>
                    <div data-aos="fade-up" className=''>
                        <Gallery photos={ROW1} />
                    </div>
                </div>

                <div className='container p-100'>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <p data-aos="fade-right" className='summer-mobile-header'><span className="highlight-text">Learn </span> Industry's In-Demand Subjects </p>
                            <p data-aos="fade-left" className='summer-section-heading'><span className="highlight-text">Big</span> Data Management </p>
                            <p data-aos="fade-left" className="subject-text"> Acquire this skill set to stay abreast in the market and what better place to do so, learn from the leaders in the industry from the leading management schools in Europe. </p>
                            
                            <p data-aos="fade-left" className='summer-section-heading'><span className="highlight-text">Negotiation </span> & Conflict </p>
                            <p data-aos="fade-left" className="subject-text"> Negotiation refers to the process of interacting in order to advance individual interests through joint action.  Let Harvard Law School ex-alumni take you through this brain wrenching essential management course with extensive case studies. </p>

                            <p data-aos="fade-left" className='summer-section-heading'><span className="highlight-text">Digital</span> Social Invention &amp; Change </p>
                            <p data-aos="fade-left" className="subject-text"> How it pleases one when they give something back to the society. Digital Social Innovation is about a unified social collaboration which innovators make to co-create knowledge and solutions for a wide range of social needs at an unimaginably large scale. Learn about digital techniques and methods to drive such innovations for the greater good. </p>

                            <p data-aos="fade-left" className='summer-section-heading'><span className="highlight-text">French Classes </span> <br/> For All Levels </p>
                            <p data-aos="fade-left" className="subject-text">  ‘When in France, do as the French do’. France doesn't just have culture; the word "culture" actually comes from France. Get to know your home for winters. Dwell into the profound history of France and the culture at the fashion capital of the world. </p>



                        </div>
                        <div className='col-sm-6'>
                            <p data-aos="fade-left" className='summer-section-heading'><span className="highlight-text">Design</span> Thinking </p>
                            <p data-aos="fade-left" className="subject-text">  Learn this entirely project based, interactive and essential management skill from the industry experts, coming from giant corporates like Microsoft.  </p>

                            <p data-aos="fade-left" className='summer-section-heading'><span className="highlight-text">Data</span> Privacy </p>
                            <p data-aos="fade-left" className="subject-text"> Data privacy has always been important. It’s why people put locks on filing cabinets and rent safety deposit boxes at their banks. But as more of our data becomes digitised, and we share more information online, data privacy is taking on greater importance. Understand what is the scope of Data Privacy and why it has become an essential field of study </p>

                            <p data-aos="fade-left" className='summer-section-heading'><span className="highlight-text">Sustainable Supply Chain</span> Management </p>
                            <p data-aos="fade-left" className="subject-text"> Customers are becoming more interested in and conscious of the sustainability practices of the companies they purchase from. Right from production to packaging to delivery, every stage is equally critical from the perspective of the buyer and the seller. Learn everything you ever needed to learn about supply chain management and gain an insight into how you can drive the strategies to be more sustainable. </p>

                            <p data-aos="fade-left" className='summer-section-heading'><span className="highlight-text">Internet</span> Of Things </p>
                            <p data-aos="fade-left" className="subject-text">  A lot of what we do is going to be controlled using artificial intelligence and the internet of things; whether you are a science major or a management student, taking these courses will help you expand your domain and increase your skill set. </p>

                            <p data-aos="fade-left" className='summer-section-heading'><span className="highlight-text">Business Ethics</span> & Sustainable Development </p>
                            <p data-aos="fade-left" className="subject-text">  Learn to carry out businesses the right way and create a sustainable environment for all the market participants as well as the consumers. </p>
                        </div>
                    </div>
                </div>
                <div data-aos="fade-in" className='container-fluid monalisa-img flexible-container p-100'>
                    <div className='row'>
                        <div className='col-sm-3'>
                        </div>
                        <div className='col-sm-4'>
                            <p data-aos="fade-up" className='big-section-heading'>Visit The Famous<span className="highlight-text"> Mona Lisa </span> In Louvre, Paris. </p>
                        </div>
                        <div className='col-sm-2'>
                        </div>
                        <div className='col-sm-3'>
                        </div>
                    </div>
                </div>
                
                <div className='container-fluid program-detail-container p-100'>
                    <div className='container'>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <p data-aos="fade-right" className='summer-mobile-header'><span className="highlight-text">Program </span> Details </p>
                        </div>
                        <div className='col-sm-6'>
                             <p data-aos="fade-left" className='program-detail-heading'><span className="highlight-text"> Program </span> Name </p>
                             <p data-aos="fade-left" className="subject-text">  Managing Innovation & Digital Transformation  </p>
                        </div>
                    </div>
                    <div className='underline'>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6'>
                             <p data-aos="fade-left" className='program-detail-heading'><span className="highlight-text"> Program </span> Dates </p>
                             <p data-aos="fade-left" className="subject-text">  24th June* - 13th July*  </p>

                             <p data-aos="fade-left" className='program-detail-heading'><span className="highlight-text"> Inclusions </span> In Programme </p>
                             <p data-aos="fade-left" className="subject-text"> – Airfare To & Fro  </p>
                             <p data-aos="fade-left" className="subject-text"> – All courses in the program (including French Classes)  </p>
                             <p data-aos="fade-left" className="subject-text"> – Breakfast and lunch, from Monday to Friday  </p>
                             <p data-aos="fade-left" className="subject-text"> – Housing on a single-occupancy basis  </p>
                             <p data-aos="fade-left" className="subject-text"> – 2 field trips to Belgium & Switzerland  </p>
                             <p data-aos="fade-left" className="subject-text"> – Free Internet access in the rooms and free wifi access on campus  </p>
                             <p data-aos="fade-left" className="subject-text"> – On campus entertainment facilities  </p>

                             <p data-aos="fade-left" className='program-detail-heading'><span className="highlight-text"> Cost Does Not </span> Include </p>
                             <p data-aos="fade-left" className="subject-text"> – Local transportation weekly pass  </p>
                             <p data-aos="fade-left" className="subject-text"> – Dinners in the week and meals during the weekend  </p>
                             <p data-aos="fade-left" className="subject-text"> – Weekend excursions  </p>
                             <p data-aos="fade-left" className="subject-text"> – Student Visa (INR 1800)  </p>

                            

                        </div>
                        <div className='col-sm-6'>
                                <p data-aos="fade-left" className='program-detail-heading'><span className="highlight-text"> Credits </span> Earned </p>
                                <p data-aos="fade-left" className="subject-text">  8 ECTS Credits  </p>

                                <p data-aos="fade-left" className='program-detail-heading'><span className="highlight-text"> Countries </span> </p>
                                <p data-aos="fade-left" className="subject-text">  France, Belgium & Switzerland  </p>

                                <div className='underline'/>

                                <p data-aos="fade-left" className='program-detail-heading'><span className="highlight-text"> All Inclusive Application </span> </p>
                                <p data-aos="fade-left" className='program-detail-heading'>  Early Bird Open (Individual & Group) - Till 1st January 2020  </p>
                                <p data-aos="fade-left" className="subject-text highlight-text">  INR 2,29,500 (All Inclusive) | Save INR 16,000 Per Registration  </p>
                                <a target='_blank' rel='noopener noreferrer' href='https://rzp.io/l/OKLO82G'>
                                    <button data-aos="fade-left" className='register-button'> Registrations Open </button>
                                </a> 

                                <p data-aos="fade-left" className='program-detail-heading'>  Register With A Friend(s) - Opens After 1st January 2020  </p>
                                <p data-aos="fade-left" className="subject-text highlight-text">  INR 2,37,500 (All Inclusive) | Save 7,500 Per Registration </p>
                                <a target='_blank' rel='noopener noreferrer' href='https://rzp.io/l/OKLO82G'>
                                    <button data-aos="fade-left" className='register-button'> Registrations Opening Soon </button>
                                </a> 

                                <p data-aos="fade-left" className='program-detail-heading'>  Individual Registration - Opens After 1st January 2020  </p>
                                <p data-aos="fade-left" className="subject-text highlight-text">  INR 2,45,000 (All Inclusive)  </p>
                                <a target='_blank' rel='noopener noreferrer' href='https://rzp.io/l/OKLO82G'>
                                    <button data-aos="fade-left" className='register-button'> Registrations Opening Soon </button>
                                </a>  
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid'>
                    <div className='row p-100'>
                        <div className='col-sm-1'>
                        </div>
                        <div className='col-sm-5 text-right'>
                            <img data-aos="fade-right" className='img-card-container' height="800px" src={AbhinayaImage} alt="Abinaya Testimonial"/>
                        </div>
                        <div className='col-sm-5 flexible-container text-left'>
                            <p data-aos="fade-left" className='subject-text'><span className="highlight-text"> </span> "Visiting France had always been one of my lifelong dreams and this came true when I got an opportunity to attend a month-long Summer School at Telecom Ecole de Management in the city of Paris, facilitated by my college SIBM, Pune. Classroom teaching was given a different touch in the form of role plays, discussions and so on which was stimulating intellectually and at the same time enjoyable too.
                            The peer-to- peer learning is immense and you get to learn a lot from the local and other exchange students. The other main highlight of this program was the opportunity to visit places like Brussels (Belgium) and Geneva (Switzerland) facilitated by TEM which was a blend of learning through visiting places like the Parlimentarium, UN and the likes as well as getting the added thrill of exploring new places!
                            I recommend everyone to make use of this once in a lifetime chance and make your dreams come true :) <br/><br/> 
                            <span className='highlight-text summer-section-heading'> Abinaya Balan </span> Summer 2017  </p> 
                            <a href='/gallery'>
                                <button data-aos="fade-left" className='register-button'> Meet All Our Students </button>
                            </a>
                        </div>
                        <div className='col-sm-1'>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

const ROW1 = [
    { src: 'https://res.cloudinary.com/labs-international/image/upload/v1573935183/Photo_from_Divyang_kbsti1_qs19mj.jpg',
     width:4,
      height: 3 },
    {
      src: 'https://res.cloudinary.com/labs-international/image/upload/v1573913979/Summer%202017/20049248_1798503160176144_1312802610_o_subkno.jpg',
      width: 4,
      height: 3
    },
    {
        src: 'https://res.cloudinary.com/labs-international/image/upload/v1573913793/Summer%202017/IMG-20170625-WA0002-01_r6clzh.jpg',
        width: 4,
        height: 3
      },
      {
        src: 'https://res.cloudinary.com/labs-international/image/upload/v1573913791/Summer%202017/20170629113030_IMG_1089-01_i4fvty.jpg',
        width: 4,
        height: 3
      }, 
      {
        src: 'https://res.cloudinary.com/labs-international/image/upload/v1573913980/Summer%202017/20049317_1798504743509319_1788785417_o_i3b0mp.jpg',
        width: 4,
        height: 3
      },
    
  ];




export default Summer20;