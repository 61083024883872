import ReactPixel from 'react-facebook-pixel';

const options = {
    autoConfig: true, 	// set pixel's autoConfig
    debug: false, 		// enable logs
};

ReactPixel.init('1500087153455305', options);

export default ReactPixel;
