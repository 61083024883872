import React from 'react';
import './winter-why.css';

const Pill1 = "https://res.cloudinary.com/labs-international/image/upload/v1572723556/Website%20Assets/pill1_eaugx0.png";

class WinterWhy extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rightImage: Pill1,
            videoDisplay: '',
        }
    }

    onMouthEnterHandlerGrid1 = () => {
        this.setState = {
            rightImage: Pill1,
            videoDisplay: 'hideVideo'
        }
    }

    render() {
        return (
            <div className='element-wrapper no-space winter-why'>
                <div className='container-fluid no-space'>
                    <div className='row no-space'>
                    </div>
                    <div className='row no-space video-container overlay'>

                        <div className='col-sm-6 overlay text-center height-full'>
                            <div className='row '>
                                <div className='col-sm-3'>
                                </div>
                                <div className='col-sm-6'>
                                    <p className='why-section-heading'> <br /> Step Ahead. </p>
                                </div>
                                <div className='col-sm-3'>
                                </div>
                            </div>

                            <div className='row why-container'>
                                <div
                                    onMouseEnterHandlerGrid1={this.onMouthEnterHandlerGrid1()}
                                    className='col-sm-6 why-grid hvr-grow'>
                                    <p className='why-header'> Get a Degree Certificate </p>
                                    <p className='why-subtitle'> Obtain degree certificate upon successful completion of your program which gives you an edge over your peers. </p>
                                </div>
                                <div className='col-sm-6 why-grid hvr-grow'>
                                    <p className='why-header'> Go On Industrial Visits </p>
                                    <p className='why-subtitle'> Visit companies of French origin multinationals to gain industry knowledge during the course of your program.</p>
                                </div>
                            </div>
                            <div className='row no-space'>
                                <div className='col-sm-6 why-grid hvr-grow'>
                                    <p className='why-header'> Earn ECTS Credits </p>
                                    <p className='why-subtitle'> Earn 3 ECTS (European Credit Transfer System) and boost the application to your dream University </p>
                                </div>
                                <div className='col-sm-6 why-grid hvr-grow'>
                                    <p className='why-header'> Internship Opportunities </p>
                                    <p className='why-subtitle'> Get a chance to apply for internship to various tied up companies with TheLabs post winter ’19 program completion. </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6 no-space'>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WinterWhy;