import React from 'react';
import {Link} from "react-router-dom";
import './mobile-menu.css';
import WhatsAppIcon from '../images/whatsapp.png';


class MobileMenu extends React.Component{

    generateWHATSAPP_API = () => {
        const num = Math.floor(Math.random() * (10 - 1 + 1) + 1);
        console.log(num);
        if(num%2===0){
            window.location.href = "https://api.whatsapp.com/send?phone=919136019289&text=Hi, I have some doubts about the program.";
        } else{
            window.location.href = "https://api.whatsapp.com/send?phone=919920787468&text=Hi, I have some doubts about the program.";
        }
    }

    hideNavigation = () => {
        document.getElementById('mobileMenu').style.height = '0%';
        document.getElementById('menu-close').style.display = 'none';
        document.getElementsByTagName('body').item(0).className = '';
    };

    render(){
        return(
            <div id={'mobileMenu'} className="mobile-menu onlyMobile">
                <div className={'container'}>
                    <div className={'row mobile-menu-container'}>
                        <div className={'col-10'}>
                            <p className={'mobile-menu-title'}> THE<b>LABS</b> </p>
                        </div>
                        <div className={'col-2'}>
                            <p id={'menu-close'} onClick={this.hideNavigation} className={'menu-close'}> × </p>
                        </div>
                    </div>
                    <div className={'row main-pill-container'}>
                        <div onClick={this.hideNavigation} className={'col-12'}>
                        <Link to='/home'><li> Home </li></Link>
                            <Link to='/summer-2020'><li> Summer <sup> 2020 </sup> </li></Link>
                            <Link to='/winter-19'><li> Winter </li></Link>
                            <Link to='/about'> <li> About </li> </Link>
                            <Link to='/gallery'><li> Gallery </li></Link>
                            <Link to='/faqs'><li> FAQs <sup> New </sup> </li></Link>
                            <hr style={{color:'#ffffff'}}/>
                            <div className='mobile-footer'>
                            <a target='_blank' without rel="noopener noreferrer" onClick={this.generateWHATSAPP_API}>
                                <button className='whatsapp-button-docked'> <img src={WhatsAppIcon} width='15px' alt='WhatsApp Icon'/>&nbsp; Immediate Assistance </button>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MobileMenu;
