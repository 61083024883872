import React from 'react';
import './winter19.css';
import WinterWhy from './winter-why/winter-why';
import WinterWhen from './winter-when/winter-when';
import WinterTestimonials from './winter-testimonials/winter-testimonials';
import WinterLearningSection from './winter-learning-section/winter-learning-section';
import Winter19Subjects from './winter-19-subjects/winter-19-subjects';
import WinterRegistration from './winter-registration/winter-registration';
import ScrollableAnchor from 'react-scrollable-anchor';
import ReactPixel from '../pixelConfig';
import Header from '../header/header';


const Paris = "https://res.cloudinary.com/labs-international/image/upload/v1572723465/Website%20Assets/paris_cjzhlb.jpg";
const Brussels = "https://res.cloudinary.com/labs-international/image/upload/v1572723465/Website%20Assets/brussels_jsovo7.jpg";
const Corporate = "https://res.cloudinary.com/labs-international/image/upload/v1572723465/Website%20Assets/corporate_tuzchr.jpg";



class Winter19 extends React.Component {

    componentDidMount(){
        ReactPixel.pageView();
    }

    constructor(props) {
        super(props);
        this.state = {
            gridImage: Paris,
        }
    }

    onMouseEnterHandlerGRID1 = () => {
        console.log('test 1');
        this.setState({
            gridImage: Paris,
        });
    }

    onMouseEnterHandlerGRID2 = () => {
        console.log('test 2');
        this.setState({
            gridImage: Corporate,
        });
    }

    onMouseEnterHandlerGRID3 = () => {
        console.log('test 3');
        this.setState({
            gridImage: Brussels,
        });
    }


    render() {
        return (
            <div className='page-wrapper'>
            <Header/>
                <div className='container-fluid'>
                <ScrollableAnchor id={'learn-more'}>
                    <div style={{ backgroundImage: 'url(' + this.state.gridImage + ')' }} className='row page-section grid-section'>
                            <div
                                onMouseEnter={this.onMouseEnterHandlerGRID1}
                                className='col-sm-4 grid-description'>
                                <p className='grid-heading'> Innovation &amp; Digital Inclusion </p>
                                <p className='grid-subtitle'> Learn and obtain credits in industry’s most in-demand subjects such as Big Data Managemnt, Supply Chain Management, Negotiation and Conflict and more. </p>
                            </div>
                            <div
                                onMouseEnter={this.onMouseEnterHandlerGRID2}
                                className='col-sm-4 grid-description'>
                                <p className='grid-heading'> Visit and learn from the giants of the industry </p>
                                <p className='grid-subtitle'> Learn and obtain credits in industry’s most in-demand subjects such as Big Data Managemnt, Supply Chain Management, Negotiation and Conflict and more. </p>
                            </div>
                            <div
                                onMouseEnter={this.onMouseEnterHandlerGRID3}
                                className='col-sm-4 grid-description'>
                                <p className='grid-heading'> Visit Belgium on a Study Tour </p>
                                <p className='grid-subtitle'> Learn and obtain credits in industry’s most in-demand subjects such as Big Data Managemnt, Supply Chain Management, Negotiation and Conflict and more. </p>
                            </div>
                    </div>
                    </ScrollableAnchor>
                </div>
                <WinterWhy />
                <WinterTestimonials />
                <Winter19Subjects />
                <WinterLearningSection />
                <WinterRegistration />
                <WinterWhen />
            </div>
        );
    }
}

export default Winter19;