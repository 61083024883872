import React from 'react';
import './faqs-winter-19.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import {Link} from 'react-router-dom';

import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion';


const WHATSAPP_API = 'https://api.whatsapp.com/send?phone=919920787468&text=Hi, I have some doubts about the program.';


const faqsWinter19 = () => {
    return (
        <div className='faqs-container'>
         <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <p className='faqs-heading'> Summer 2020 FAQs </p>
                        <p className='faqs-description'> Frequently asked questions about the Summer Program 2020  </p>
                    </div>
                </div>
                <div className='row'>
                <Accordion>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How do I apply for the Summer School’20?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    You can register yourself by filling a form on the website at: <b><u> <a href='https://rzp.io/l/OKLO82G'> Register Summer Program 2020 </a> </u></b>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Are the seats limited for each program?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Yes, the program has a limited number of seats. The registrations are made on a first-come, first-served basis. So it is wise to register early to be sure to have a place. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What is the total cost (including tuition, housing fees, Airfare to & fro)?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    The total cost of the programs are divided into three groups
                    <br/><br/>
                    <li> Early Bird Registrations For Individual & Group Applicants (INR 2,29,500 - Registrations Before 1st January 2020)</li>
                    <br/>
                    <b> After 1st January </b>
                    <br/>                    <br/>
                    <li> Group Registrations (Two or More Applicants) - (INR 2,37,500) </li>
                    <li> Individual Registration - (INR 2,45,000) </li>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What are the inclusions in the cost?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    The cost includes the following:
                        <li> Flight Tickets</li>
                        <li> Tuition fees for all courses in the program  </li>
                        <li> Breakfast and lunch, from Monday to Friday </li> 
                        <li> Housing on a single-occupancy basis </li> 
                        <li> 2 field trips to Belgium & Switzerland </li> 
                        <li> Free Internet access in the rooms and free wifi access on campus </li>  
                        <li> On campus entertainment facilities </li> 
                        <li> Visa assistance </li> 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What are the conditions of acceptance?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    You need to provide the following documents:
                        <li> A copy of your university transcripts</li>
                        <li> A resume  </li>
                        <li> A copy of your passport </li> 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Do I need to prove my English level?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    We do not ask for a proof of your English level, but all candidates must have a sufficient level in English to attend courses taught in English. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    I have never learned French. Am I still eligible?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Yes. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Do I have to buy textbooks for language or core courses that I am going to attend? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    No. The classes do not require any particular textbooks as the teaching is mainly based on lectures and presentations. Faculty will provide all course material.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How many courses (language and core) are included in the tuition fees?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    All courses are included in the fees (including French courses).
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Am I allowed to add or remove subjects in the course?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    No, The program is pre-set and no change can be made.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Is it possible to receive credits?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    It’s up to your university to make credit transfers and to decide how many credits you will obtain for the summer program. IMT grants 6 credits for main program and 2 credits for the French classes.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What type of accommodation will participants be provided with during the program? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Accommodation is on campus in single occupancy rooms with a private bathroom and kitchen. Laundry facilities are located on the ground floor of the building and are common to all students.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What is the price range for weekend trips?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    The School does not arrange any trips on weekends. However, it will be possible for summer school students to be assisted by full-time student interns for organizing short trips around the School: thematic visits in Paris, Versailles Castle, Disneyland, etc.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What happens after I have registered for the Summer School’20?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    We will reach out to you via email, where you will be provided with instructions to attach a list of documents to be submitted to the university. This list of documents forms a part of your application which will be reviewed by the University.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Where do I have to submit the list of documents for my application and in which format?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    You must compile the list of documents in a single PDF file format (.pdf) and send it as a reply to the mail which contains the instructions for the application.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What happens after I submit my application for the Summer School’20?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    TheLabs sends out your application to Institut Mines Telecom, Paris for assessment and acceptance. The University reviews your application and sends the confirmation for acceptance to TheLabs. within 1-2 weeks of sending the application.  </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        How much time does it take to find out whether my application has been accepted or rejected?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        The University reviews your application and sends the confirmation for acceptance to TheLabs within 1-2 days of sending the application. You may feel free to contact TheLabs at any point of time to find out the status of your application.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Why do I have to pay INR 1145 while registering for Summer School’20?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        We generate more than 100+ fresh enquiries on a daily basis for our programs and short courses; the registration fees of INR 1145 (0.5% Of Total Fees) (Refundable: In case your application doesn't get approved by University) is required to block your seat for Summer School’20. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>


            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Am I allowed to arrange alternative accommodation different from what is included in the program?                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    We strongly urge you not to. The price remains the same and includes accommodation. Likewise our accommodation is on-campus and ensures you will mix not only with students from the summer school, but with other full-time students who will organize events and outings throughout the program. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>


            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Do I need to apply for a Schengen visa? If so, then what type of visa must it be?                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    As an Indian national, students require student visa to enter into the Schegen Territory. We will be assisting you in the matters of Visa.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Is EMI option available?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Yes, EMI option is available depending on your credit card with respect to the payment gateway. While your application is getting processed, feel free to ask our representative for an EMI option. Please note, however - It comes with an added 2.5% surcharge on total amount.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What are the financing options available?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <b> EMI </b>
                    <p>
                    EMI option is available depending on your credit card with respect to the payment gateway. While your application is getting processed, feel free to ask our representative for an EMI option. Please note, however - It comes with an added 2.5% surcharge on total amount.
                    </p>
                    <b> Part Payment </b>
                    <p>
                    You can break down your payment in multiple parts as per your convenience. Please co-ordinate with an executive to know more about the same.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
                </div>
                <br></br>
                <p className='faqs-subheading'> Still Need Help? </p>
                <a href={WHATSAPP_API}>
                    <button className='whatsapp-button-light'> Get Immediate Reply </button>
                </a>
            </div>
        <hr/>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <p className='faqs-heading'> Winter 2019 FAQs </p>
                        <p className='faqs-description'> Frequently asked questions about the Winter Program 2019  </p>
                    </div>
                </div>
                <div className='row'>
                <Accordion>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How do I apply for the Winter School’19?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    You can register yourself by filling a form on the website at: <b><u> <Link to='/#apply'> Register Winter 19 </Link> </u></b>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Are the seats limited for each program?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Yes, the program has a limited number of seats. The registrations are made on a first-come, first-served basis. So it is wise to register early to be sure to have a place. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What happens after I have registered for the Winter School’19?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    We will reach out to you via email, where you will be provided with instructions to attach a list of documents to be submitted to the university. This list of documents forms a part of your application which will be reviewed by the University.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Where do I have to submit the list of documents for my application and in which format?

                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    You must compile the list of documents in a single PDF file format (.pdf) and send it as a reply to the mail which contains the instructions for the application.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>


            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What happens after I submit my application for the Winter Program’19?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    TheLabs sends out your application to Institut Mines Telecom for assessment and acceptance. The University reviews your application and sends the confirmation for acceptance to TheLabs. within 1-2 weeks of sending the application.                    </p>
                </AccordionItemPanel>
            </AccordionItem>


            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How much time does it take to find out whether my application has been accepted or rejected?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    The University reviews your application and sends the confirmation for acceptance to TheLabs within 1-2 weeks of sending the application. You may feel free to contact TheLabs at any point of time to find out the status of your application.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>



            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Why do I have to pay 1,500 while registering for Winter School’19?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    We generate more than 100 fresh enquiries on a daily basis for our programs and short courses; the registration fees of 1,500 (Refundable: In case your application doesn't get approved by University) is required to block your seat for Winter School’19. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>


            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Do I need to apply for a Schengen visa? If so, then what type of visa must it be?                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    As an Indian national, students require student visa. We will be assisting you in the matters of Visa.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Is EMI option available?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Yes, EMI option is available. While your application is getting processed, feel free to ask our representative for an EMI option. Please note, however - It comes with an added 2.5% surcharge on total amount.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What are the financing options available?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <b> EMI </b>
                    <p>
                    EMI option is available. While your application is getting processed, feel free to ask our representative for an EMI option. Please note, however - It comes with an added 2.5% surcharge on total amount.
                    </p>
                    <b> Part Payment </b>
                    <p>
                    You can break down your payment in multiple parts as per your convenience. Please co-ordinate with an executive to know more about the same.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
                </div>
                <br></br>
                <p className='faqs-subheading'> Still Need Help? </p>
                <a href={WHATSAPP_API}>
                    <button className='whatsapp-button-light'> Get Immediate Reply </button>
                </a>
            </div>
        </div>
    );
}
export default faqsWinter19;