import React from 'react';
import './website-navigation.css';
import {Link} from 'react-router-dom';
import MenuImage from '../images/menu-button.png';



class WebsiteNavigation extends React.Component{

    showNavigation = () => {
        document.getElementById('mobileMenu').style.height = '100%';
        document.getElementById('menu-close').style.display = 'block';
        document.getElementsByTagName('body').item(0).className = 'no-scroll-window';
    };

    render(){
        return(
            <div className='container-fluid wn-wrapper'>
                <div className='container mobileHeader onlyMobile'>
                    <div className='row wn-inner-mobile'>
                        <div className='col-6 text-left'>
                            <Link to='/'><li id='company'> THE<b>LABS </b> </li></Link>
                        </div>
                        <div className='col-6 text-right'>
                        <li onClick={this.showNavigation} id='company'>
                            <img alt='Menu' src={MenuImage} width='17px'/> 
                        </li>
                        </div>
                    </div>
                </div>
                <div className='container onlyDesktop'>
                    <div className='row'>
                    <div className='col-sm-3 wn-inner'>
                            <Link to='/'><li id='company'> THELABS </li></Link>
                        </div>
                        <div className='col-sm-6 wn-inner text-center'>
                            <Link to='/'><li> Home </li></Link>
                            <Link to='/summer-2020'><li> Summer'20 </li></Link>
                            <Link to='/winter-19'><li> Winter'19 </li></Link>
                            <Link to='/about'> <li> About </li> </Link>
                            <Link to='/gallery'><li> Gallery </li></Link>
                            <Link to='/faqs'><li> FAQs </li></Link>
                        </div>
                        <div className='col-sm-3 wn-inner'>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WebsiteNavigation;