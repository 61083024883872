import React from 'react';
import './winter-testimonials.css'
import Modal from 'react-responsive-modal';
import ScrollableAnchor from 'react-scrollable-anchor';

const Shreenidhi = "https://res.cloudinary.com/labs-international/image/upload/v1572723621/Website%20Assets/shreenidhi-cropped_n03sce.png";
const Ishita = "https://res.cloudinary.com/labs-international/image/upload/v1572723621/Website%20Assets/ishita_ubo9sx.png";
const Lavin = "https://res.cloudinary.com/labs-international/image/upload/v1572723622/Website%20Assets/lavin_hrnwq6.jpg";



const ShreenidhiReview = "I firstly thank Institut Mines-Telecom business school, Paris, France for conducting such an amazing International Winter School Program which I would say is a once in a lifetime experience that wouldn’t change." +
"I didn’t know what to expect before I came to IMT and the best part was that it would have beat any expectation that I could of had."+
" We were about 32 students who went through TheLabs organization. The interns both in India as well as in Paris were truly amazing and took care of us from the very beginning."+
" The first day we just had the campus visit and from the next day, we went to various company visits where we got the insight of their workspace, the companies past and the goals they have. "+
" The companies included in the program were ‘Linagora’, ‘Station F’ and  ‘Allianz’.  ‘L’oreal’, ‘Ticket.com’ and ‘Renault’ made campus visits. The best part about the program was there was a 2-day trip to Brussels for the European Union Parliament and Commission visit. "+
" The EU visits were followed by a special lecture that was delivered by an EU representative working in Luxembourg. It gave us an insight into how the European Union works and what it does for its people. The next week were lectures of a different material which was highly important."+
" Apart from all the study time we explored the place to the fullest. We travelled by metros, buses and what, not too different places. All thanks to Google maps. The country was beautiful, the experiences were amazing, and the people I met… Well, that was my favourite part."+
" I can’t say more about the trip because it is more than what words can describe: fun, love, study, and happiness. The relationships formed through this experience were deeply meaningful and will certainly be long-lasting."+
" IMT Winter School will forever be a fond memory, and I would highly recommend this experience to anyone considering it!";

const IshitaReview = "Flying to Europe was one of my dreams that was fulfilled last year in December. The best part was that this trip benefitted me both academically and culturally and moreover I learnt so much about life on this trip. We were taken, managed and brought back safely during this entire trip, taking into consideration the political condition of France  at that time. Our accomodation was absolutely great and taught us new ways of living. We got use to walking and taking the trains and metros and by the end of the trip the Paris rail line was on the tips of our fingers. Visiting the European Union Parliament and the European Union Commission will definitely be one of the highlights of the trip. This opportunity was one in a million. Also a visit to the UNESCO was thrilling and very very educating. We realised that we wouldn't be able to get our hands on this kind of stuff, if it wasn’t for TheLabs.  This has definitely been one of the most memorable trips of my life, as I not only grew academically but mentally and emotionally too."

const LavinReview = "I was a part of The Winter Program ( Dec'18) organised in Paris by TheLabs . The various courses were very useful in understanding the future of technology . My favourite lecture was by Dr. Bhanu Neupane at UNESCO on Artificial Intelligence and Sustainable Living. The program helped me make new friends and get to know the different rules and culture between India and France.-Lavin"

class WinterTestimonials extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shreenidhi: false,
            ishita: false,
            lavin: false
        }
    };

    onOpenModal = (Whose) => {

        switch(Whose){
            case 'Shreenidhi':
            this.setState({
                shreenidhi: true,
            });
            break;
            case 'Ishita':
            this.setState({
                ishita: true,
            });
            break;
            case 'Lavin':
            this.setState({
                lavin: true,
            });
            break;
            default:
        }

        
    };

    onCloseModal = (Whose) => {
        this.setState({
            shreenidhi: false,
            ishita: false,
            lavin: false
        });
    };

    render() {
        return (
        <ScrollableAnchor id={'testimonials'}>
            <div className='element-wrapper testimonial-section-wrapper'>
                <div className='container-fluid testimonial-container'>
                    <div className='row'>
                        <div className='col-sm-3 testimonial-heading-container'>
                            <p className='testimonial-section-heading'> Stories that <br /> last a lifetime. </p>
                            <p className='testimonial-section-subtitle'> Testimonials from <br /> students of Winter 2018. </p>
                        </div>

                        <div onClick={() => this.onOpenModal('Shreenidhi')} className='col-sm-3 testimonial-grid-container'>
                            <div style={{ backgroundImage: 'url(' + Shreenidhi + ')' }} className='testimonial-grid'>
                            </div>
                            <p className='testimonial-grid-subtitle'> Shreenidhi Mulupuri <br /> 
                                <span className='testimonial-grid-university'>Anna University</span>
                            </p>
                        </div>

                        <Modal open={this.state.shreenidhi} onClose={this.onCloseModal} center>
                            <div style={{ backgroundImage: 'url(' + Shreenidhi + ')' }} className='modal-header-image'>
                            </div>
                            <p className='modal-heading'> Shreenidhi Mulupuri </p>
                            <p className='modal-subtitle'> Winter 2018 </p>
                            <p className='testimonial'> {ShreenidhiReview} </p>
                            <p onClick={this.onCloseModal} className='close-text'>Close</p>
                        </Modal>

                        <div onClick={() => this.onOpenModal('Ishita')} className='col-lg-3 testimonial-grid-container'>
                            <div style={{ backgroundImage: 'url(' + Ishita + ')' }} className='testimonial-grid'>
                            </div>
                            <p className='testimonial-grid-subtitle'> Ishita Biswas <br /> <span className='testimonial-grid-university'> NMIMS University </span> </p>
                        </div>

                        <Modal open={this.state.ishita} onClose={this.onCloseModal} center>
                            <div style={{ backgroundImage: 'url(' + Ishita + ')' }} className='modal-header-image'>
                            </div>
                            <p className='modal-heading'> Ishita Biswas </p>
                            <p className='modal-subtitle'> Winter 2018 </p>
                            <p className='testimonial'> {IshitaReview} </p>
                            <p onClick={this.onCloseModal} className='close-text'>Close</p>
                        </Modal>

                        <div onClick={() => this.onOpenModal('Lavin')}  className='col-sm-3 testimonial-grid-container'>
                            <div style={{ backgroundImage: 'url(' + Lavin + ')' }} className='testimonial-grid'>
                            </div>
                            <p className='testimonial-grid-subtitle'> Lavin Agarwal <br /><span className='testimonial-grid-university'> NMIMS University </span> </p>
                        </div>

                        <Modal open={this.state.lavin} onClose={this.onCloseModal} center>
                            <div style={{ backgroundImage: 'url(' + Lavin + ')' }} className='modal-header-image'>
                            </div>
                            <p className='modal-heading'> Lavin Agarwal </p>
                            <p className='modal-subtitle'> Winter 2018 </p>
                            <p className='testimonial'> {LavinReview} </p>
                            <p onClick={this.onCloseModal} className='close-text'>Close</p>
                        </Modal>
                    </div>
                </div>
            </div>
            </ScrollableAnchor>
        );
    }
}

export default WinterTestimonials;