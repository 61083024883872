import React from 'react';
import './top-nav.css'
import { Link } from 'react-router-dom';
const WHATSAPP_API = 'https://api.whatsapp.com/send?phone=919920787468&text=Hi, I have some doubts about the program.';


class TopNav extends React.Component {
    render() {
        return (
            <div className='container-fluid top-nav-container'>
                <div className='row'>
                    <div className='col-sm-12 inner-nav'>
                        <a href='https://drive.google.com/open?id=1keSdEzuXFubMP15jXS9OPGW1KK8nzIDZ'>
                            <li> Brochure - Summer </li>
                        </a>
                        <a target='_blank' without rel="noopener noreferrer" href={WHATSAPP_API}>
                            <li> WhatsApp: +91 99207-874-68 <sup className='success-label'>  </sup> </li>
                       </a>
    
                    </div>
                </div>
            </div>
        );
    }
}

export default TopNav;