import React from 'react';
import './winter-registration.css';

import ScrollableAnchor from 'react-scrollable-anchor';


const Plane = "https://res.cloudinary.com/labs-international/image/upload/v1572723889/Website%20Assets/plane_vsg9jh.png";
const Tuition = "https://res.cloudinary.com/labs-international/image/upload/v1572723889/Website%20Assets/tuition_qv4ram.png";
const Accomodation = "https://res.cloudinary.com/labs-international/image/upload/v1572723888/Website%20Assets/accomodation_v5wflr.png";
const Belgium = "https://res.cloudinary.com/labs-international/image/upload/v1572723888/Website%20Assets/belgium_jip72r.png";
const EU = "https://res.cloudinary.com/labs-international/image/upload/v1572723888/Website%20Assets/eu_zjpo5v.png";
const Bus = "https://res.cloudinary.com/labs-international/image/upload/v1572723888/Website%20Assets/bus_swnq1c.png";
const StationF = "https://res.cloudinary.com/labs-international/image/upload/v1572723889/Website%20Assets/stationf_zic8qb.png";
const Help = "https://res.cloudinary.com/labs-international/image/upload/v1572723888/Website%20Assets/help_uszl3g.png";


class WinterRegistration extends React.Component {

    render() {
        return (
        <ScrollableAnchor id={'apply'}>
            <div className='element-wrapper'>
                <div className='container'>
                    <p className='registration-section-header text-center'> Inclusions </p>
                    <div className='row text-center'>
                        <div className='col-4 col-lg-3 inclusion-unit'>
                            <div> <img className='inclusion-icon' alt='airline-tickets' src={Plane}></img></div>
                            <div className='inclusion-title'> To &amp; Fro Airline Tickets </div>
                        </div>
                        <div className='col-4 col-lg-3 inclusion-unit'>
                            <div> <img className='inclusion-icon' alt='airline-tickets' src={Tuition}></img></div>
                            <div className='inclusion-title'> Tuition Fees </div>
                        </div>
                        <div className='col-4 col-lg-3 inclusion-unit'>
                            <div> <img className='inclusion-icon' alt='airline-tickets' src={Accomodation}></img></div>
                            <div className='inclusion-title'> Accomodation </div>
                        </div>
                        <div className='col-4 col-lg-3 inclusion-unit'>
                            <div> <img className='inclusion-icon' alt='airline-tickets' src={Belgium}></img></div>
                            <div className='inclusion-title'> Visit to Brussels, Belgium </div>
                        </div>
                        <div className='col-4 col-lg-3 inclusion-unit'>
                            <div> <img className='inclusion-icon' alt='airline-tickets' src={EU}></img></div>
                            <div className='inclusion-title'> Visit to European Commission </div>
                        </div>
                        <div className='col-4 col-lg-3 inclusion-unit'>
                            <div> <img className='inclusion-icon' alt='airline-tickets' src={Bus}></img></div>
                            <div className='inclusion-title'> Transportation To Belgium </div>
                        </div>
                        <div className='col-4 col-lg-3 inclusion-unit'>
                            <div> <img className='inclusion-icon' alt='airline-tickets' src={StationF}></img></div>
                            <div className='inclusion-title'> Company / Industrial Visits </div>
                        </div>
                        <div className='col-4 col-lg-3 inclusion-unit'>
                            <div> <img className='inclusion-icon' alt='airline-tickets' src={Help}></img></div>
                            <div className='inclusion-title'> Student Interns For Assistance </div>
                        </div>
                    </div>
                    <div className='row text-center'>
                        
                    </div>
                </div>
                <div className='container-fluid register-background'>
                    <div className='row'>
                        <div className='col-sm-3 text-center'>
                            <p className='register-title'> Individual Student Registration </p> 
                            <p className='register-amount'> INR 2,15,000 </p> 
                            <p className='register-subtitle'> per student </p>
                            <div className='button-container'>
                            <a target='_blank' rel='noopener noreferrer' href='https://rzp.io/l/NVSIbv4'>
                            <button className='register-button'> Registrations Completed </button>
                            </a> 
                            </div>
                        </div>
                        <div className='col-sm-3 text-center'>
                        <b> <p className='discount-label'> INR {215000 - 208550} OFF </p> </b>
                            <p className='register-title'> Group Of 2 </p> 
                            <p className='register-amount'> INR 2,08,550 </p> 
                            <p className='register-subtitle'> per student </p>
                            <div className='button-container'>
                            <a target='_blank' rel='noopener noreferrer' href='https://rzp.io/l/sd1cKqt'>
                            <button className='register-button'> Registrations Completed </button>
                            </a> 
                            </div>
                        </div>
                        <div className='col-sm-3 text-center'>
                        <b> <p className='discount-label'> INR {215000 - 205325} OFF </p> </b>
                            <p className='register-title'> Group Of 3 </p> 
                            <p className='register-amount'> INR 2,05,325 </p> 
                            <p className='register-subtitle'> per student </p>
                            <div className='button-container'>
                            <a target='_blank' rel='noopener noreferrer' href='https://rzp.io/l/g8br4Mn'>
                            <button className='register-button'> Registrations Completed </button>
                            </a> 
                            </div>
                        </div>
                        <div className='col-sm-3 text-center registration-unit'>
                        <b> <p className='discount-label'> INR {215000 - 202100} OFF </p> </b>
                            <p className='register-title'> Group Of 4 or more </p> 
                            <p className='register-amount'> INR 2,02,100 </p> 
                            <p className='register-subtitle'> per student </p>
                            <div className='button-container'>
                            <a target='_blank' rel='noopener noreferrer' href='https://rzp.io/l/AofLsiD'>
                                <button className='register-button'> Registrations Completed </button>
                            </a> 
                            </div>
                        </div>
                    </div>
                </div>   
            </div>
        </ScrollableAnchor>
        );
    }
}

export default WinterRegistration;
