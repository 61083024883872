import React from 'react';
import './homepage.css';
import 'hover.css';
import Summer20 from '../summer20';

class Homepage extends React.Component {
    render() {
        return (
            <div className='page-wrapper'>
                <Summer20/>
            </div>

        )
    }

}

export default Homepage;