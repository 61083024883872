import React from 'react';
import Routes from './routes';
import { BrowserRouter } from 'react-router-dom'
import { ParallaxProvider } from 'react-scroll-parallax';
import Footer from './footer';
import TopNav from './top-nav/top-nav';
import WebsiteNavigation from './WebsiteNavigation';
import MobileMenu from './MobileMenu';


function App() {
  return (
    <ParallaxProvider>
      <BrowserRouter>
        <TopNav/>
        <MobileMenu/>
        <WebsiteNavigation/>
          <Routes />
        <Footer/>
      </BrowserRouter>
    </ParallaxProvider>
  );
}

export default App;
