import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Homepage from './homepage/homepage';
import Winter19 from './winter19/winter19';
import faqsWinter19 from './winter19/faqs-winter-19/faqs-winter-19'
import GalleryPage from './gallery/index';
import About from './about';
import Summer20 from './summer20';

const Routes = () => {
    return (
        <div>
            <Switch>
                <Route path="/" exact component={Homepage} />
                <Route path="/home" exact component={Homepage} />
                <Route path="/winter-19" exact component={Winter19} />
                <Route path="/summer-2020" exact component={Summer20} />
                <Route path="/about" exact component={About} />
                <Route path="/faqs" exact component={faqsWinter19} />
                <Route path="/gallery" exact component={GalleryPage}/>
            </Switch>
        </div>
    );
}

export default Routes;