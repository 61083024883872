import React from 'react';
import './footer.css';
import summerbadge from '../images/summer-20/summer-20-badge.png'


const Footer = () => {
    return (
        <div>
            <div className='container-fluid footer-container'>
                <div className='container footer-content'>
                    <div className='row'>
                        <div className='col-3 text-left'>
                          <img width="100px" src={summerbadge} alt="Summer Badge"/>
                        </div>
                        <div className='col-sm-3'>
                            <div className='footer-header'>
                                About TheLabs
                            </div>
                            <p className='footer-content'>
                                We are an edutech company focussed globalized education which aims
                                to provide umpteen options to students to enhance their skills
                                and explore the world accordingly.
                            </p>
                        </div>
                        <div className='col-sm-3'>
                            <div className='footer-header'>
                                Reach Us
                            </div>
                            <div className='footer-content'>
                                <li><b> WhatsApp: </b> +91 99207-874-68 </li>
                                <li><b> Instagram:</b> <a href='https://instagram.com/thelabsofficial/'> @thelabsofficial </a> </li>
                                <li><b> Office:</b> C Block, 101 - Krishna Building, Opp. Milap Cinemas, Kandivali (W), Mumbai - 64 </li>
                            </div>
                        </div>
                        <div className='col-3'>
                        <div className='footer-header'>
                                Menu
                        </div>
                        <div className='footer-content'>
                                <li> <a href="/gallery"> —Past Program Gallery </a> </li> 
                        </div>
                        </div>
                    </div>
                </div>
                <div className='container footer-content'>
                    <div className='row'>
                        <div className='col-3'>
                        </div>
                        <div className='col-sm-3'>
                            <div className='footer-header'>
                               Our Vision
                            </div>
                            <p className='footer-content'>
                             TheLabs is curating short programs to enable students experience countries around the world, and learn skills that boost their career. We are one of the premier edu-tech companies in India working with International partners to have a one-stop portal for all your dreams. 
                            </p>
                        </div>
                        <div className='col-sm-3'>
                            <div className='footer-header'>
                                Issue?
                            </div>
                            <div className='footer-content'>
                                <li><b> Management Mail: </b> info@thelabs.in </li>
                            </div>
                        </div>
                        <div className='col-3'>
                        </div>
                    </div>
                </div>
                <div className='container'>
                <hr className='break'/>
                    <div className='row'>
                        <div className='col-sm-12 text-center copyright-text'>
                            Copyright © 2016 - {new Date().getFullYear()} | All Rights Reserved by Shenzo LabTech Private Limited
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Footer;