import React from 'react';
import './winter-learning-section.css';

const WinterParisImage = "https://res.cloudinary.com/labs-international/image/upload/v1572723769/Website%20Assets/winter-paris_ksbfxi.jpg";

class WinterLearningSection extends React.Component {
    render() {
        return (
            <div className='element-wrapper'>
                <div style={{ backgroundImage: 'url(' + WinterParisImage + ')' }} className='learning-wrapper'>
                    <div className='container'>
                    <div className='row'>
                        <div className='col-sm-5'>
                            <div className='wls-heading-container'>
                                <p className='wls-heading'> A Little Learning Goes A Long Way. </p>
                                <p className='wls-description'> As a part of this program, you will be visiting historic churches,
                                museums and the legendary Louvre Museum. </p>
                            </div>
                        </div>
                    </div>
                    </div>
                
                </div>
            </div>
        )
    }
}

export default WinterLearningSection;