import React from 'react';
import './banner.css';
import WhatsAppIcon from '../../images/whatsapp.png';

const WHATSAPP_API = 'https://api.whatsapp.com/send?phone=919920787468&text=Hi, I have some doubts about the program.';

class banner extends React.Component {

    constructor(props){
        super(props)
        this.handleScroll = this.handleScroll.bind(this);
    }


    handleScroll = (event) => {
        let element = event.target;
        console.log("Time to change the element");

        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            // do something at end of scroll
        }
    }

    render() {
    const img = this.props.slideImage;
    const sliderImage = {
        backgroundImage: 'url(' + img + ')'
    }

        return (
            
            <div style={sliderImage} className='container-fluid slider animated fadeIn delay-1s'>
                <div className="row">
                    <div onScroll={this.handleScroll} className='col-sm-12 align-items-center'>
                        <div className='slider-inner-container text-center'>
                            <div className='slider-title'> {this.props.headingText} </div>
                            <div className='slider-subtitle'> {this.props.subtitleText} </div>
                            <a href={this.props.buttonURL}>
                                <button className='slider-button hvr-fade'> {this.props.buttonText} </button>
                            </a>
                            <a target='_blank' without rel="noopener noreferrer" href={WHATSAPP_API}>
                                <button className='whatsapp-button-docked animated pulse infinite'> <img src={WhatsAppIcon} width='12px' alt='WhatsApp Icon'/>  </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default banner;